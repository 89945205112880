/* Add this at the top of your file */
.ag-theme-quartz {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif !important;
}

.ag-theme-quartz .ag-header-cell {
  border-right: 1px solid #e5e7eb !important;
  border-left: none !important;
  border-top: 1px solid #e5e7eb !important;
  border-bottom: 1px solid #e5e7eb !important;
  font-family: inherit !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  color: #4b5563 !important;
  background-color: #f9fafb !important;
}

.ag-theme-quartz .ag-header-cell:first-child {
  border-left: 1px solid #e5e7eb !important;
}

.ag-theme-quartz .ag-header-cell:last-child {
  border-top-right-radius: 0 !important;
}

.ag-theme-quartz .ag-header-cell:first-child {
  border-top-left-radius: 0 !important;
}

.ag-theme-quartz .ag-row .ag-cell:hover {
  background-color: #f3f4f6 !important;
}

.ag-theme-quartz .ag-header-cell:hover {
  background-color: #f3f4f6 !important;
}

/* Remove outer border and box-shadow from the grid container */
.ag-theme-quartz {
  border: none !important;
  box-shadow: none !important;
}

/* Remove border from the inner wrappers that surround the entire grid */
.ag-theme-quartz .ag-root-wrapper {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  overflow: visible !important;
}

/* Optionally, remove header/footer outer borders if they are part of the issue */
.ag-theme-quartz .ag-header,
.ag-theme-quartz .ag-paging-panel {
  border-bottom: none !important;
  box-shadow: none !important;
}

.ag-theme-quartz .ag-root-wrapper-container {
  border: none !important;
  box-shadow: none !important;
}

/* Add cell font styling to match ExtractDetail.tsx */
.ag-theme-quartz .ag-cell {
  border-right: 1px solid #e5e7eb !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: 1px solid #e5e7eb !important;
  font-family: inherit !important;
  font-size: 0.875rem !important; /* 14px - matches Tailwind's text-sm */
  color: #374151 !important; /* Tailwind's text-gray-700 */
  display: flex !important;
  align-items: center !important;
  border-radius: 0 !important;
}

.ag-theme-quartz .ag-cell:first-child {
  border-left: 1px solid #e5e7eb !important;
}

.ag-theme-quartz .ag-cell-last-column {
  border-right: 1px solid #e5e7eb !important;
}

/* Replace the existing .has-citations rule with this */
.ag-theme-quartz .ag-row .ag-cell.ag-cell-has-citations {
  cursor: pointer !important;
}

/* Add this rule to make the first column text bold */
.ag-theme-quartz .ag-cell.ag-cell-first-right-pinned,
.ag-theme-quartz .ag-cell:first-of-type {
  font-weight: 500 !important;
}

/* Update row borders */
.ag-theme-quartz .ag-row {
  border: none !important;
}

/* Ensure all cells in the grid don't have any border radius */
.ag-theme-quartz .ag-cell,
.ag-theme-quartz .ag-header-cell,
.ag-theme-quartz .ag-row,
.ag-theme-quartz .ag-row-first,
.ag-theme-quartz .ag-row-last {
  border-radius: 0 !important;
}

/* Additional rules to ensure all AG Grid components have no rounded corners */
.ag-theme-quartz .ag-tool-panel,
.ag-theme-quartz .ag-menu,
.ag-theme-quartz .ag-popup-child,
.ag-theme-quartz .ag-popup-editor,
.ag-theme-quartz .ag-rich-select,
.ag-theme-quartz .ag-select,
.ag-theme-quartz .ag-tool-panel-wrapper,
.ag-theme-quartz .ag-floating-filter,
.ag-theme-quartz .ag-status-bar,
.ag-theme-quartz .ag-panel {
  border-radius: 0 !important;
}

/* Make sure the outer container doesn't have rounded corners either */
.ag-theme-quartz,
.ag-theme-quartz * {
  --ag-border-radius: 0 !important;
}

/* Override AG Grid CSS Variables */
.ag-theme-quartz {
  --ag-card-radius: 0 !important;
  --ag-border-radius: 0 !important;
  --ag-card-shadow: none !important;
  --ag-borders: solid 1px !important;
  --ag-border-color: #e5e7eb !important;
}

/* Handle any last-row special cases */
.ag-theme-quartz .ag-row:last-of-type .ag-cell {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* Hide horizontal scrollbar when not needed */
.ag-theme-quartz .ag-horizontal-scroll-viewport {
  height: 0 !important;
  overflow: hidden !important;
  transition: height 0.1s ease-out !important;
}

.ag-theme-quartz .ag-center-cols-viewport {
  scroll-behavior: auto !important;
}

/* Only show scrollbar when content overflows */
.ag-theme-quartz .ag-horizontal-scroll-viewport.ag-scrollbar-visible {
  height: 10px !important; /* Standard scrollbar height */
  overflow: auto !important;
}

/* Target the bottom scrollbar container */
.ag-theme-quartz .ag-body-horizontal-scroll {
  height: 0 !important;
  min-height: 0 !important;
  overflow: hidden !important;
  transition: height 0.1s ease-out !important;
  margin-bottom: 0 !important;
}

/* Show scrollbar container when needed */
.ag-theme-quartz .ag-body-horizontal-scroll.ag-scrollbar-visible {
  height: 10px !important;
  overflow: visible !important;
}

/* Hide scrollbar when all columns fit */
.ag-theme-quartz .ag-body-horizontal-scroll:not(.ag-scrollbar-visible) {
  display: none !important;
}

/* Fix the bottom border and padding when scrollbar is hidden */
.ag-theme-quartz .ag-center-cols-container {
  border: none !important;
}

/* Ensure no extra spacing below the grid when no scrollbar */
.ag-theme-quartz .ag-root {
  overflow: hidden !important;
}

/* Hide any horizontal scroll overflow */
.ag-theme-quartz .ag-body-horizontal-scroll-viewport.ag-scrollbar-invisible {
  display: none !important;
}

/* Fix cell active state */
.ag-theme-quartz .ag-cell:active,
.ag-theme-quartz .ag-cell:focus {
  outline: none !important;
  box-shadow: none !important;
  z-index: 0 !important;
}

/* Fix the focused cell styling to not show any special highlight */
.ag-theme-quartz .ag-cell-focus {
  border: 1px solid #e5e7eb !important;
  outline: none !important;
  z-index: 0 !important;
}

/* Fix cell selection color */
.ag-theme-quartz .ag-cell.ag-cell-range-selected {
  background-color: transparent !important;
}

/* Fix any cell selection or focus effects */
.ag-theme-quartz .ag-cell-focus,
.ag-theme-quartz .ag-cell-no-focus {
  border-color: #e5e7eb !important;
}

.ag-theme-quartz .ag-cell.ag-cell-inline-editing {
  border: 1px solid #e5e7eb !important;
  background-color: white !important;
}

/* Remove focus outline on cells */
.ag-theme-quartz:focus {
  outline: none !important;
}

.ag-theme-quartz .ag-cell:focus-within {
  outline: none !important;
  border: 1px solid #e5e7eb !important;
}

/* Remove additional focus styling */
.ag-theme-quartz
  .ag-cell-focus:not(.ag-cell-range-selected):not(.ag-cell-range-single-cell) {
  border: 1px solid #e5e7eb !important;
  outline: none !important;
}

/* Ensure the cell click doesn't create a visual cue */
.ag-theme-quartz .ag-cell:active {
  background-color: inherit !important;
}

/* Cancel any default hover state */
.ag-theme-quartz .ag-row-hover {
  background-color: inherit !important;
}

/* Simplified view specific styles */
.ag-theme-quartz.simplified-view .ag-row {
  height: 48px !important; /* Fixed height for simplified view */
}

.ag-theme-quartz.simplified-view .ag-cell {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  height: 100% !important;
  line-height: 48px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-width: 200px !important; /* Minimum width for cells in simplified view */
}

/* Fix for simplified cell content to properly show ellipsis */
.ag-theme-quartz.simplified-view .simplified-cell-content {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important; /* Ensure it takes full width */
  width: 100% !important;
  box-sizing: border-box !important;
  padding: 0 12px !important;
}

/* Override ReactMarkdown styles in simplified view */
.ag-theme-quartz.simplified-view .simplified-cell-content .prose {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
}

/* Fix paragraph margins in simplified view */
.ag-theme-quartz.simplified-view .simplified-cell-content p {
  margin: 0 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.ag-theme-quartz.simplified-view .cell-content {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important; /* Ensure content respects cell width */
  width: 100% !important;
}

.ag-theme-quartz.simplified-view .ag-header-cell {
  height: 60px !important;
  min-width: 200px !important; /* Match cell min-width */
}

/* Additional vertical alignment adjustment for simplified view */
.ag-theme-quartz.simplified-view .ag-cell-wrapper {
  height: 100% !important;
  align-items: center !important;
  width: 100% !important; /* Ensure wrapper fills cell width */
}

/* Make sure simplified view columns are properly sized */
.ag-theme-quartz.simplified-view .ag-header-row,
.ag-theme-quartz.simplified-view .ag-row {
  display: flex !important;
  width: fit-content !important; /* Allow row to expand to fit columns */
  min-width: 100% !important; /* Ensure row is at least as wide as container */
}

/* Ensure horizontal scroll works properly with wider columns */
.ag-theme-quartz.simplified-view
  .ag-body-horizontal-scroll.ag-scrollbar-visible {
  height: 14px !important; /* Slightly larger scrollbar for simplified view */
  margin-bottom: 4px !important;
}

/* Make simplified view text truncate with ellipsis */
.simplified-cell-content {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
}

/* Tooltip styling for full text display */
.tooltip-content {
  background-color: white !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 6px !important;
  padding: 10px 14px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  color: #374151 !important;
  max-height: 300px !important;
  overflow-y: auto !important;
  white-space: normal !important;
  text-overflow: initial !important;
  z-index: 1000 !important;
  pointer-events: auto !important;
  max-width: 400px !important;
  word-wrap: break-word !important;
}
